import React from "react";


const HeroSection = () => (
    <header className="hero">
      <h2>Welcome to the Ordering portal</h2>
      <p>This interface connects with User, Product, and Order microservices.</p>
    </header>
  );

export default HeroSection;