
const env = process.env.NODE_ENV;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
    USERS: `${process.env.REACT_APP_USERS_SERVICE_URL || 'https://localhost:4000'}/api/users`,
    EMPLOYEE: `${process.env.REACT_APP_USERS_SERVICE_URL || 'https://localhost:4000'}/api/users`,
    PRODUCTS: `${process.env.REACT_APP_PRODUCTS_SERVICE_URL || 'https://localhost:5000'}/api/products`,
    ORDERS: `${process.env.REACT_APP_ORDERS_SERVICE_URL || 'https://localhost:7000'}/api/users`,

    // USERS: `${(env === 'production')? "https://user-ms.inventory.local:4000/api/users" : "http://user-service:4000/api/users" }`,
    // EMPLOYEE: `${(env === 'production')? "https://user-ms.inventory.local:4000/api/users" : "http://user-service:4000/api/users" }`,
    // PRODUCTS: `${(env === 'production')? "https://inventory.pishukla.people.aws.dev/api/users" : "http://product-service:5000/api/products" }`,
    // ORDERS: `${(env === 'production')? "https://inventory.pishukla.people.aws.dev/api/users" : "http://order-service:7000/api/orders" }`,

    //PRODUCTS: `${process.env.REACT_APP_PRODUCTS_SERVICE_URL || 'https://localhost:5000'}/api/products`,
    //USERS: `${process.env.REACT_APP_USERS_SERVICE_URL || 'https://localhost:4000'}/api/users`,

    // USERS: "https://localhost:4000/api/users",
    // PRODUCTS: "https://localhost:4000/api/products",
    // EMPLOYEE: "https://localhost:4000/api/user",
    // ORDERS: "https://localhost:4000/api/orders",

    // USERS: "https://inventory.pishukla.people.aws.dev/api/users",
    // PRODUCTS: "https://inventory.pishukla.people.aws.dev/api/products",
    // EMPLOYEE: "https://inventory.pishukla.people.aws.dev/api/user",
    // ORDERS: "https://inventory.pishukla.people.aws.dev/api/orders",

  
  };